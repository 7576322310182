<template>
  <div>
    <h2 v-if="faqTitle">{{ faqTitle }}</h2>
    <template v-for="(faq, index) in faqs">
      <Accordion
        v-if="faq.question"
        :key="index"
        :description="faq.question"
        data-cy="faq-item"
      >
        <div slot="content">
          <AccordionItem>
            <h3
              slot="title"
              class="headline--5 u-margin-top--0 u-margin-bottom--0"
            >
              {{ faq.question }}
            </h3>
            <div slot="content">
              <JsonRichText v-bind="faq.answer" />
              <Button
                v-if="faq.cta && faq.cta.title && faq.cta.href"
                :href="$link.create(faq.cta.href)"
                class="u-margin-bottom--2 button--secondary"
              >
                {{ faq.cta.title }}
              </Button>
            </div>
          </AccordionItem>
        </div>
      </Accordion>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { FaqProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "Faqs",
  components: {
    Accordion,
    AccordionItem,
    JsonRichText,
    Button,
  },
  props: {
    faqTitle: {
      type: String,
      required: false,
      default: null,
    },
    faqs: {
      type: Array as PropType<FaqProps[]>,
      required: true,
    },
  },
});
</script>
