<template>
  <div data-cy="highlight-list">
    <h2 data-cy="highlight-list__title">{{ highlightsTitle }}</h2>
    <div v-if="highlights.length < carouselBreakValue" class="l-grid">
      <div
        v-for="(highlight, index) of highlights"
        :key="index"
        class="l-grid__cell l-grid__cell--6-col"
      >
        <HighlightCard
          :title="highlight.title"
          :image="highlight.image"
          :description="highlight.description"
          :products="highlight.products"
          data-cy="highlight-list__single-highlight-card"
        />
      </div>
    </div>
    <CarouselResponsive
      v-if="highlights.length >= carouselBreakValue"
      description="Highlights carousel"
      :items-xs="1"
      :items-sm="2"
      :items-md="2"
      :items-lg="2"
      :items-xl="2"
    >
      <CarouselResponsiveItem
        v-for="(highlight, index) of highlights"
        :key="index"
      >
        <HighlightCard
          :title="highlight.title"
          :image="highlight.image"
          :description="highlight.description"
          :products="highlight.products"
          data-cy="highlight-list__multiple-highlight-card"
        />
      </CarouselResponsiveItem>
    </CarouselResponsive>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselResponsive from "atlas/src/components/CarouselResponsive/CarouselResponsive.vue";
import CarouselResponsiveItem from "atlas/src/components/CarouselResponsive/CarouselResponsiveItem.vue";
import { HighlightCardProps } from "~/components/HighlightCard/Props";
import HighlightCard from "~/components/HighlightCard/HighlightCard.vue";

export default Vue.extend({
  name: "HighlightList",
  components: {
    HighlightCard,
    CarouselResponsive,
    CarouselResponsiveItem,
  },
  props: {
    highlights: {
      type: Array as PropType<HighlightCardProps[]>,
      required: true,
    },
    highlightsTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      carouselBreakValue: 2,
    };
  },
});
</script>
