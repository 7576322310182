<template>
  <Tile
    :title="title"
    :link="link"
    button-text="Browse FAQs"
    data-cy="faqs-cta"
  >
    Browse our frequently asked questions, as we might already have the answer.
  </Tile>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { FaqsCTAProps } from "~/components/FaqsCTA/Props";

export default Vue.extend({
  name: "FaqsCTA",
  components: {
    Tile,
  },
  props: {
    destinationName: {
      type: String as PropType<FaqsCTAProps["destinationName"]>,
      required: true,
    },
    destinationUrl: {
      type: String as PropType<FaqsCTAProps["destinationUrl"]>,
      required: true,
    },
  },
  computed: {
    title() {
      return `Got questions about ${this.destinationName}?`;
    },
    link() {
      return `/${this.$i18n.locale}${this.destinationUrl}#faqs`;
    },
  },
});
</script>
