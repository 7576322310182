<template>
  <Card :image="image" class="u-height--full">
    <template slot="heading">
      <span data-cy="highlight-card-title">
        {{ title }}
      </span>
    </template>
    <template slot="content">
      <div class="highlight-card">
        <div
          class="highlight-card__description u-margin-bottom--2"
          data-cy="highlight-card-description"
        >
          {{ description }}
        </div>
        <div v-for="(product, index) of products" :key="index">
          <Button
            class="button--secondary button--block u-margin-top--1"
            data-cy="highlight-card-button"
            :href="$link.create(product.url)"
          >
            {{ product.displayName }}
          </Button>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { HighlightCardProps } from "./Props";

export default Vue.extend({
  name: "HighlightCard",
  components: {
    Card,
    Button,
  },
  props: {
    title: {
      type: String as PropType<HighlightCardProps["title"]>,
      required: true,
      default: undefined,
    },
    description: {
      type: String as PropType<HighlightCardProps["description"]>,
      required: false,
      default: undefined,
    },
    image: {
      type: Object as PropType<HighlightCardProps["image"]>,
      required: false,
      default: undefined,
    },
    products: {
      type: Array as PropType<HighlightCardProps["products"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>

<style lang="scss">
@import "./highlight-card";
</style>
