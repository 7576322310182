<template>
  <div v-if="isValid" data-cy="gallery-container">
    <AtlasGallery
      :title="title"
      :images="images"
      :lazy-load="lazyLoad"
      :modalOptions="{ showAlt: false }"
      data-cy="gallery"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AtlasGallery from "atlas/src/components/Gallery/Gallery.vue";
import { GalleryProps } from "./Props";
import Image from "~/lib/types/Image";

export default Vue.extend({
  name: "Gallery",
  components: {
    AtlasGallery,
  },
  props: {
    title: {
      type: String as PropType<GalleryProps["title"]>,
      required: false,
      default: "",
    },
    images: {
      type: Array as PropType<GalleryProps["images"]>,
      required: true,
      default: [] as Image[],
    },
    lazyLoad: {
      type: Boolean as PropType<GalleryProps["lazyLoad"]>,
      required: false,
      default: false,
    },
  },
  computed: {
    isValid(): boolean {
      return Array.isArray(this.images) && this.images.length > 0;
    },
  },
});
</script>
