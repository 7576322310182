<template>
  <Tile
    class="
      tile--horizontal tile--text-left tile--small
      u-background-color--sand
      tile--unboxed
    "
    :title="title"
    :icon="icon"
    data-cy="fluid-price-alert"
  />
</template>

<script lang="ts">
import Vue from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
export default Vue.extend({
  name: "FluidPriceAlert",
  components: {
    Tile,
  },
  data() {
    return {
      title: "Prices may go up, so secure your trip today",
      icon: "bullhorn-outline",
    };
  },
});
</script>
