<template>
  <component
    :is="tagName"
    v-if="text && tagName"
    :id="elementId"
    :data-anchor-text="dataAnchorText"
    data-cy="heading-panel-element"
  >
    {{ text }}
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { HeadingPanelProps } from "./Props";
import { HeadingTypes } from "~/lib/types/Contentstack";

export default Vue.extend({
  name: "HeadingPanel",
  props: {
    tagName: {
      type: String as PropType<HeadingPanelProps["tagName"]>,
      required: true,
    },
    text: {
      type: String as PropType<HeadingPanelProps["text"]>,
      required: true,
    },
    includeInSubNavigation: {
      type: Boolean as PropType<HeadingPanelProps["includeInSubNavigation"]>,
      required: true,
    },
    subNavigationText: {
      type: String as PropType<HeadingPanelProps["subNavigationText"]>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    dataAnchorText(): string | undefined {
      if (this.tagName === HeadingTypes.H2 && this.includeInSubNavigation) {
        return this.subNavigationText || this.text;
      }
      return undefined;
    },
    elementId(): string | undefined {
      if (this.dataAnchorText) {
        return this.dataAnchorText.replace(/\s+/g, "-").toLowerCase();
      }
      return undefined;
    },
  },
});
</script>
