<template>
  <div data-cy="form-panel" class="form-panel">
    <LazyHydrate never>
      <div v-html="formScript"></div>
    </LazyHydrate>
    <Modal
      class="default form-panel--submission-modal"
      :show-modal="showFormSuccessModal"
      :description="submissionHeading"
      @close="closeModal"
    >
      <template slot="heading"></template>
      <template slot="content">
        <Icon name="send" class="icon--size-6" />
        <h5>{{ submissionHeading }}</h5>
        <JsonRichText :json="submissionMessage" />
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import LazyHydrate from "vue-lazy-hydration";
import JsonRichText from "../JsonRichText/JsonRichText.vue";
import { FormPanelProps } from "./Props";

export default Vue.extend({
  name: "FormPanel",
  components: {
    Modal,
    JsonRichText,
    Icon,
    LazyHydrate,
  },
  props: {
    formId: {
      type: String as PropType<FormPanelProps["formId"]>,
      required: true,
    },
    submissionHeading: {
      type: String as PropType<FormPanelProps["submissionHeading"]>,
      required: true,
    },
    submissionMessage: {
      type: Object as PropType<FormPanelProps["submissionMessage"]>,
      required: true,
    },
  },
  data() {
    return {
      showFormSuccessModal: false,
    };
  },
  computed: {
    formScript(): string {
      const scriptSrc = `${this.$config.public.formstackEmbedUrl}/${this.formId}`;
      return `<script src="${scriptSrc}"><` + "/script>";
    },
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("form_submitted")) {
      this.showFormSuccessModal = true;
    }
    this.updateHiddenInputFieldForRedirection();
    this.updateHiddenInputFieldForCurrentDate();
  },
  methods: {
    updateHiddenInputFieldForRedirection() {
      const formContainer = this.$el.querySelector(
        ".fsform-container"
      ) as HTMLInputElement;

      const localeInputElement = this.$el.querySelector(
        ".fsHidden[label='Locale']"
      ) as HTMLInputElement;

      const redirectionUrlInputElement = this.$el.querySelector(
        ".fsHidden[label='Redirection Url']"
      ) as HTMLInputElement;

      if (redirectionUrlInputElement && localeInputElement) {
        const pathArray = window.location.pathname.split("/");
        const [urlLocale, urlPath] = pathArray.slice(1, pathArray.length);

        if (urlLocale && urlPath) {
          const fsForm = window.fsApi().getForm(formContainer.dataset.formid); // different from prop
          const fsRedirectionUrlInputElement = fsForm.getField(
            `${redirectionUrlInputElement.dataset.fsFieldId}`
          );
          const fsLocaleInputElement = fsForm.getField(
            `${localeInputElement.dataset.fsFieldId}`
          );

          fsLocaleInputElement.setValue(urlLocale);
          fsRedirectionUrlInputElement.setValue(urlPath);
        }
      }
    },
    updateHiddenInputFieldForCurrentDate() {
      const formContainer = this.$el.querySelector(
        ".fsform-container"
      ) as HTMLInputElement;

      const hiddenInputElement = this.$el.querySelector(
        ".fsHidden[label='Current date']"
      ) as HTMLInputElement;

      if (hiddenInputElement) {
        const currentDate = dayjs().format("MM/DD/YYYY");
        const fsForm = window.fsApi().getForm(formContainer.dataset.formid); // different from prop
        const fsInputElement = fsForm.getField(
          `${hiddenInputElement.dataset.fsFieldId}`
        );

        fsInputElement.setValue(currentDate);
      }
    },
    closeModal() {
      this.showFormSuccessModal = false;
    },
  },
});
</script>

<style lang="scss">
@import "./form-panel";
</style>
